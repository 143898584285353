'use client'

import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material'

// Types
import type { ListItemProps } from '@/types/components/atoms/items/list'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const ListItemAtom = (props: ListItemProps) => {
	// Props
	const { lng, href, text, icon, endIcon, level, onlyIcon, target, handleClick } = props

	// Variables
	const { t } = useTranslation(lng)
	const pathname = usePathname()
	const isActive = pathname.indexOf(`${href}/`) === 0
	const isCurrent = pathname === href

	return (
		<ListItem disablePadding sx={{ '& a': { width: 1 } }}>
			<Link href={href} target={target}>
				<ListItemButton
					onClick={handleClick}
					sx={{
						justifyContent: onlyIcon ? { xs: 'inherit', md: 'center' } : 'inherit',
						minHeight: 48,
						px: 1.5,
						pl: level ? level * 3 : 1.5,
						color: (href !== `/${lng}` && isActive) || isCurrent ? 'primary.contrastText' : undefined,
						bgcolor: (href !== `/${lng}` && isActive) || isCurrent ? 'primary.light' : undefined,
						borderRadius: 1,
						'&:hover': { bgcolor: (href !== `/${lng}` && isActive) || isCurrent ? 'primary.light' : undefined }
					}}
				>
					{icon && (
						<ListItemIcon
							sx={{
								justifyContent: 'center',
								minWidth: 0,
								mr: onlyIcon ? { xs: 1, md: 'auto' } : 1,
								color: (href !== `/${lng}` && isActive) || isCurrent ? 'primary.contrastText' : 'primary.dark'
							}}
						>
							{icon}
						</ListItemIcon>
					)}

					<ListItemText
						primary={t(text)}
						sx={{
							...(onlyIcon && { display: { md: 'none' } }),
							'& .MuiTypography-root': {
								fontWeight: 500,
								textOverflow: 'ellipsis',
								textWrap: 'nowrap',
								overflow: 'hidden'
							}
						}}
					/>

					<Box component="span" display={{ xs: 'inline-flex', md: onlyIcon ? 'none' : 'inline-flex' }}>
						{endIcon}
					</Box>
				</ListItemButton>
			</Link>
		</ListItem>
	)
}

export default ListItemAtom
